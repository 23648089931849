import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import Routes from './Routes'
import Store from './Redux/Store';
import { Provider } from 'react-redux';
import './Styles/Styles.scss'
import { ToastContainer } from 'react-toastify';
import axios from 'axios'
import Helpers from './Components/Helpers/Helpers';
import { setSession } from './Redux/Actions'

{/* <React.StrictMode>
  <Provider store={Store}>
    <Routes />
  </Provider>
</React.StrictMode> */}

//
// ─── VALIDACION DE SESION ───────────────────────────────────────────────────────
//
const App = () => {
  const [content, setContent] = useState(<div style={{
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed'
  }}>
    <img src={Helpers.config.resourcesUrl({ url: '/images/dashboard/preloading.svg' })} alt="Preloading" />
  </div>);

  useEffect(() => {
    axios({
      url: Helpers.config.api.url + '/users/session',
      method: 'POST',
      data: {
        jwt: Helpers.localStorage.getJWT()
      }
    }).then(res => {
      return res.data;
    }).then(res => {
      if (Helpers.config.verifyToken()) {
        if (res.message === 'error_session_api') {
          localStorage.clear();
          setContent(<Provider store={Store}>
            <Routes type="auth" />
            <ToastContainer />
          </Provider>);
        } else {
          Store.dispatch(setSession({
            ...res.data,
            file_photo_user: Helpers.config.resourcesUrl({ url: res.data.file_photo_user })
          }));
          Helpers.localStorage.setSession({
            jwt: res.data.jwt
          });
          setContent(<Provider store={Store}>
            <Routes />
            <ToastContainer />
          </Provider>);
        }
      }
    }).catch(req => {
      console.log(req);
    });
  }, []);

  /* return <React.StrictMode>
    {content}
  </React.StrictMode>; */
  return content;
}

ReactDOM.render(
  <App />,
  document.getElementById('root')
);

Helpers.config.devInformation();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
