import React, { useEffect, useState } from 'react'
import Card from 'react-bootstrap/esm/Card';
import { Link, useParams, useHistory } from 'react-router-dom';
import Controllers from '../../Api/Controllers';
import Helpers from '../../Components/Helpers/Helpers';
import parse from 'html-react-parser';
import { useSelector } from 'react-redux';
import { FormCustom, Input } from '../../Components/Form/Form';
import Button from 'react-bootstrap/esm/Button';
import ReactPlaceholder from 'react-placeholder/lib';

const Searching = _ => {
    const { keyword } = useParams()
    const store = useSelector(store => store)

    const [rows, setRows] = useState({
        articles: [],
        events: [],
        foros: [],
        notis: [],
        courses: [],
    })
    const [ready, setReady] = useState(false)
    const history = useHistory()

    useEffect(_ => {
        Controllers.dashboard.get_searching({
            p_keyword: keyword
        }).then(res => {
            setRows(res.data)
            setReady(true)
        })
    }, [keyword])
    const [textFilter, setTextFilter] = useState('')

    return <>
        {/* <Card className="mb-4">
            <Card.Body>
                <FormCustom viewSubmit={false} onSubmit={_ => {
                    history.push(Helpers.config.convertUrl({ url: '/buscador/' + textFilter }))
                }}>
                    <Input
                        classNameParent="col-12"
                        text="Buscar"
                        placeholder="Buscar"
                        append={[
                            <Button type="submit">
                                <i className="fa fa-search"></i>
                            </Button>
                        ]}
                        required
                        onChange={e => setTextFilter(e.currentTarget.value)}
                    />
                </FormCustom>
            </Card.Body>
        </Card> */}
        <Card>
            <Card.Header>
                Resultados
            </Card.Header>
            <Card.Body>
                <ReactPlaceholder ready={ready} showLoadingAnimation rows={10}>
                    {rows.articles.length || rows.events.length || rows.notis.length || rows.foros.length || rows.courses.length ? <>
                        <div className="row">
                            {rows.articles.length ? <div className="col-12">
                                <h3 className="mb-4 font-weight-bold text-danger">Balance de Vida</h3>
                                {React.Children.toArray(rows.articles.map(a => <>
                                    <section className="d-flex pb-5">
                                        {a.file_images ? <Link to={Helpers.config.convertUrl({ url: '/blog/' + a.token_article })}>
                                            <img src={Helpers.config.resourcesUrl({ url: a.file_images })} width="80" height="80" />
                                        </Link> : ''}
                                        <div className="pl-4">
                                            <Link to={Helpers.config.convertUrl({ url: '/blog/' + a.token_article })} className="text-decoration-none">
                                                <h4 className="font-weight-bold">{a.name_article}</h4>
                                            </Link>
                                            <p className="d-flex flex-column flex-md-row justify-content-between mt-3">
                                                <span className="mr-3">
                                                    <i className="fa fa-calendar-alt mr-2"></i>
                                                    <span>{a.date_article}</span>
                                                </span>
                                                <Link className="mr-3 text-decoration-none" to={Helpers.config.convertUrl({ url: '/categoria/1/' + a.slug_category })}>
                                                    <i className="fa fa-folder mr-2"></i>
                                                    <span>{a.name_category}</span>
                                                </Link>
                                                <span className="mr-3">
                                                    <i className="fa fa-comment mr-2"></i>
                                                    <span>{a.qty_comment_article}</span>
                                                </span>
                                            </p>
                                            <div className="mt-3">
                                                {parse(a.content_article)}
                                            </div>
                                        </div>
                                    </section>
                                    <hr />
                                </>))}
                            </div> : ''}
                            {rows.events.length ? <div className="col-12">
                                <h3 className="mb-4 font-weight-bold text-danger">Eventos</h3>
                                {React.Children.toArray(rows.events.map(a => <>
                                    <section className="d-flex pb-5">
                                        {a.file_images ? <Link to={Helpers.config.convertUrl({ url: '/evento/' + a.token_event })}>
                                            <img src={Helpers.config.resourcesUrl({ url: a.file_images })} width="80" height="80" />
                                        </Link> : ''}
                                        <div className="pl-4">
                                            <Link to={Helpers.config.convertUrl({ url: '/evento/' + a.token_event })} className="text-decoration-none">
                                                <h4 className="font-weight-bold">{a.name_event}</h4>
                                            </Link>
                                            <p className="d-flex flex-column flex-md-row justify-content-between mt-3">
                                                <span className="mr-3">
                                                    <i className="fa fa-calendar-alt mr-2"></i>
                                                    <span>{a.date_event}</span>
                                                </span>
                                                <Link className="mr-3 text-decoration-none" to={Helpers.config.convertUrl({ url: '/categoria/evento/1/' + a.slug_category })}>
                                                    <i className="fa fa-folder mr-2"></i>
                                                    <span>{a.name_category}</span>
                                                </Link>
                                                <span className="mr-3">
                                                    <i className="fa fa-comment mr-2"></i>
                                                    <span>{a.qty_comment_event}</span>
                                                </span>
                                            </p>
                                            <div className="mt-3">
                                                {parse(a.content_event)}
                                            </div>
                                        </div>
                                    </section>
                                    <hr />
                                </>))}
                            </div> : ''}
                            {rows.notis.length ? <div className="col-12">
                                <h3 className="mb-4 font-weight-bold text-danger">Noti Super</h3>
                                {React.Children.toArray(rows.notis.map(a => <>
                                    <section className="d-flex pt-5">
                                        {a.file_images ? <Link to={Helpers.config.convertUrl({ url: '/noti/' + a.token_noti })}>
                                            <img src={Helpers.config.resourcesUrl({ url: a.file_images })} width="80" height="80" />
                                        </Link> : ''}
                                        <div className="pl-4">
                                            <Link to={Helpers.config.convertUrl({ url: '/noti/' + a.token_noti })} className="text-decoration-none">
                                                <h4 className="font-weight-bold">{a.name_noti}</h4>
                                            </Link>
                                            <p className="d-flex flex-column flex-md-row justify-content-between mt-3">
                                                <span className="mr-3">
                                                    <i className="fa fa-calendar-alt mr-2"></i>
                                                    <span>{a.date_noti}</span>
                                                </span>
                                                <Link className="mr-3 text-decoration-none" to={Helpers.config.convertUrl({ url: '/categoria/noti/1/' + a.slug_category })}>
                                                    <i className="fa fa-folder mr-2"></i>
                                                    <span>{a.name_category}</span>
                                                </Link>
                                                <span className="mr-3">
                                                    <i className="fa fa-comment mr-2"></i>
                                                    <span>{a.qty_comment_noti}</span>
                                                </span>
                                            </p>
                                            <div className="mt-3">
                                                {parse(a.content_noti)}
                                            </div>
                                        </div>
                                    </section>
                                    <hr />
                                </>))}
                            </div> : ''}
                            {rows.foros.length ? <div className="col-12">
                                <h3 className="mb-4 font-weight-bold text-danger">Foros</h3>
                                {React.Children.toArray(rows.foros.map(r => {
                                    return <Card className="mb-2">
                                        <Card.Body className="d-flex position-relative pt-4">
                                            <Link to={Helpers.config.convertUrl({ url: '/foro/' + r.token_foro })}>
                                                <img src={Helpers.config.resourcesUrl({ url: r.file_photo_foro })} width="50" height="50" style={{
                                                    borderRadius: 50
                                                }} />
                                            </Link>
                                            <div className="d-flex flex-column pl-3 w-100">
                                                <Link to={Helpers.config.convertUrl({ url: '/foro/' + r.token_foro })}>
                                                    <h6 className="bg-dark text-white p-2">
                                                        {r.name_foro}
                                                    </h6>
                                                </Link>
                                                <p>{r.observation_foro}</p>
                                                <small className="text-muted">{r.date_created}</small>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                }))}
                            </div> : ''}
                            {rows.courses.length ? <div className="col-12">
                                <h3 className="mb-4 font-weight-bold text-danger">Cursos</h3>
                                <div className="form-row">
                                    {React.Children.toArray(rows.courses.map(v => {
                                        return <section className="col-12 col-sm-6 col-md-3 mb-4">
                                            {v.categories.includes(store.session.id_category) ? <Link onClick={_ => {
                                                setHasToken(true)
                                                setReadyVideo(false)
                                            }} to={Helpers.config.convertUrl({ url: '/buscador-de-videos/' + v.token_video })}>
                                                <img src={Helpers.config.resourcesUrl({ url: v.file_images })} width="100%" height="150" />
                                                <div className="bg-white p-3">
                                                    <h6 className="text-dark text-center">{v.name_course}</h6>
                                                </div>
                                            </Link> : <div style={{
                                                opacity: '.5',
                                                cursor: 'not-allowed'
                                            }}>
                                                    <img src={Helpers.config.resourcesUrl({ url: v.file_images })} width="100%" height="150" />
                                                    <div className="bg-white p-3 d-flex flex-column align-items-center justify-content-center" style={{
                                                        minHeight: 70
                                                    }}>
                                                        <h6 className="text-dark text-center">{v.name_course}</h6>
                                                        <strong className="text-danger">No pertence a tu área</strong>
                                                    </div>
                                                </div>}
                                        </section>
                                    }))}
                                </div>
                            </div> : ''}
                        </div>
                    </> : <div className="alert alert-warning">
                            ¡Opps! No se han encontrado coincidencias.
                    </div>}
                </ReactPlaceholder>
            </Card.Body>
        </Card>
    </>
}

export default Searching;